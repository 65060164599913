.aboutUs-main{
  z-index: 1;
}

.aboutus1stpage {
  display: flex;

  flex-direction: row;

  justify-content: space-between;
}

.pragma-title-aboutus {
  font-family: Noto Serif;

  font-style: display medium;

  font-size: 60px;

  line-height: 82px;

  color: #241757;
}

.pragma-subtitle-aboutus {
  display: flex;

  flex-direction: column;

  font-family: spartan;

  font-style: Mixed;

  font-size: 38px;

  line-height: 60px;

  color: #000000;
}

.pragma-smalltitle-aboutus {
  display: flex;

  flex-direction: column;

  font-family: Spartan;

  font-style: Medium;

  font-size: 20px;

  line-height: 40px;

  color: #81849b;
}

/* right side */

@media (max-width: 480px) {
  .aboutusvectorimg {
    width: 100px;

    /* margin-left: -55px; */
  }
}

/* 2nd page */

.aboutus2nd {
  margin-top: 5rem;
}

.aboutusimages {
  display: flex;

  flex-direction: row;
}

.aboutusimages2 {
  margin-top: 8rem;
}

.aboutusimages4 {
  margin-top: 8rem;
}

/* 3rd */

.our-mission-heading {
  font-family: Noto Serif;

  font-style: bold;

  font-size: 40px;

  line-height: 54px;

  color: #133876;

  font-weight: 400;
}

.our-mission-main-content {
  font-family: Spartan;

  font-size: 18px;

  line-height: 30px;

  color: #81849b;

  font-weight: 400;
}

.aboutus-content-heading {
  font-family: Noto Serif;

  font-style: bold;

  font-size: 40px;

  line-height: 54px;

  color: #133876;

  font-weight: 400;
}

.aboutus-more-content {
  font-family: Spartan;

  font-size: 18px;

  line-height: 30px;

  color: #81849b;

  font-weight: 400;
}

.Meettheteam-title {
  font-family: Noto Serif;

  font-style: bold;

  font-size: 40px;

  line-height: 54px;

  color: #133876;

  text-align: center;

  font-weight: 400;
}

.ajnit-sir {
  display: flex;

  flex-direction: row;

  justify-content: space-between;
}

.anjit-sir-pic-block {
  background: #5380ff;

  width: 22rem;

  height: 26rem;
}

.anjit-sir-pic {
  margin-top: 5rem;

  margin-left: 7rem;

  width: 20rem;

  height: 24rem;
}

@media (max-width: 480px) {
  .anjit-sir-pic-block {
    background: #5380ff;

    width: 15rem;

    height: 19rem;
  }

  .anjit-sir-pic {
    margin-top: 3rem;

    margin-left: 4rem;

    width: 15rem;

    height: 19rem;
  }
}

.anjit-sir-bio {
  display: flex;

  flex-direction: column;

  justify-content: space-between;

  margin-left: 20rem;
}

.anjit-sir-bio-conent {
  font-family: spartan;

  font-style: Regular;

  font-size: 18px;

  line-height: 35px;

  color: #81849b;
}

.anjit-sir-name {
  font-family: Noto Serif;

  font-style: bold;

  font-size: 40px;

  line-height: 35px;

  color: #133876;
}

.anjit-sir-sub-title {
  font-family: Spartan;

  font-style: Medium;

  font-size: 20px;

  line-height: 35px;

  color: #81849b;

  margin-top: -10rem;
}

.sadia-mam {
  margin-top: 8rem;

  display: flex;

  flex-direction: row;

  justify-content: space-between;
}

.sadia-mam-bio {
  display: flex;

  flex-direction: column;

  justify-content: space-between;

  /* margin-left: 20rem; */
}

.sadia-mam-bio-conent {
  font-family: spartan;

  font-style: Regular;

  font-size: 18px;

  line-height: 35px;

  color: #81849b;
}

.sadia-mam-name {
  font-family: Noto Serif;

  font-style: bold;

  font-size: 40px;

  line-height: 35px;

  color: #133876;
}

.sadia-mam-sub-title {
  font-family: Spartan;

  font-style: Medium;

  font-size: 20px;

  line-height: 35px;

  color: #81849b;

  /* margin-top: -10rem; */
}

.sadia-mam-pic-block {
  background: #5380ff;

  width: 22rem;

  height: 26rem;
}

.sadia-mam-pic {
  margin-top: 5rem;

  margin-left: -7rem;

  width: 20rem;

  height: 24rem;
}

.sadia-mam-sub-title {
  margin-top: -10rem;
}

.aboutus-footer {
  margin-top: 10rem;
}

.telephone-icon svg {
  fill: #5380ff;
}

@media (max-width: 480px) {
  .anjit-sir-bio {
    margin-left: 0rem;

    margin-top: 24rem;

    margin-left: -193px;
  }

  .anjit-sir-sub-title {
    margin-top: 0rem;
  }

  .anjit-sir-name {
    margin-top: 5px;
  }
}

@media (max-width: 480px) {
  .sadia-mam-bio {
    display: flex;

    flex-direction: column;

    justify-content: space-between;

    /* margin-left: 20rem; */

    margin-top: 31rem;

    margin-left: 1rem;
  }

  .sadia-mam-pic-block {
    background: #5380ff;

    width: 122rem;

    height: 21rem;

    margin-right: 3rem;
  }

  .sadia-mam-pic-block {
    background: #5380ff;

    width: 122rem;

    height: 21rem;

    margin-right: 0rem;

    margin-left: -4rem;
  }

  .sadia-mam-pic {
    margin-top: 5rem;

    margin-left: -7rem;

    width: 15rem;

    height: 20rem;
  }

  .sadia-mam-bio-conent {
    font-family: spartan;

    font-style: Regular;

    font-size: 18px;

    line-height: 35px;

    width: 243px;

    color: #81849b;
  }

  .sadia-mam-sub-title {
    margin-top: 0rem;
  }
}

/* follow us */

.site {
  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;
}

.wrapperr {
  display: flex;

  height: 30vh;

  flex-direction: row;

  padding: 0 20px;

  align-items: center;

  justify-content: center;
}

.fab {
  margin: auto;
}

.social {
  color: #fff;

  transition: all 0.35s;

  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.social:hover {
  text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);

  transition: all ease 0.5s;

  -moz-transition: all ease-in 0.5s;

  -webkit-transition: all ease-in 0.5s;

  -o-transition: all ease-in 0.5s;
}

.facebook {
  color: #4267b2;
}

.twitter {
  color: #1da1f2;
}

.youtube {
  color: #c4302b;
}

.pinterest {
  color: #c8232c;
}

.instagram {
  color: transparent;

  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );

  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );

  background-clip: text;

  -webkit-background-clip: text;
}

.tumblr {
  color: #34526f;
}

.linkedin {
  color: #0072b1;
}

.bg-ico {
  display: flex;

  background-color: #fff;

  width: 90px;

  height: 90px;

  line-height: 90px;

  margin: 0 5px;

  text-align: center;

  position: relative;

  overflow: hidden;

  border-radius: 28%;

  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.1);

  opacity: 0.99;

  -webkit-transition: background-color 2s ease-out;

  -moz-transition: background-color 2s ease-out;

  -o-transition: background-color 2s ease-out;

  transition: background-color 2s ease-out;

  text-decoration: none;
}

.bg-ico:hover {
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.8);
}

#facebook:hover {
  background-color: #4267b2;
}

#twitter:hover {
  background-color: #1da1f2;
}

#youtube:hover {
  background-color: #c4302b;
}

#pinterest:hover {
  background-color: #c8232c;
}

#instagram:hover {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );

  background: -webkit-radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}

#tumblr:hover {
  background-color: #34526f;
}

#linkedin:hover {
  background-color: #0072b1;
}

.facebook:hover,
.twitter:hover,
.youtube:hover,
.pinterest:hover,
.instagram:hover,
.tumblr:hover,
.linkedin:hover {
  color: #fff;

  transform: scale(1.3);
}

.text-decoration-none {
  text-decoration: none;
}

.heading-follow {
  text-align: center;

  text-align: center;

  font-family: Noto Serif;

  font-style: bold;

  font-size: 40px;

  line-height: 54px;

  color: #133876;

  font-weight: 400;
}
