body {
  background-size: auto;
  position: relative;
  /* background: radial-gradient(
    ellipse,
    rgba(255, 224, 176, 1) 10%,
    rgba(71, 212, 255, 1) 100%
  ); */
  /* background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
  min-height: 100vh; */
}

.fixed-bg {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  /* z-index: 5; */
}

a {
  text-decoration: none;
  display: flex;
  color: inherit;
  align-items: center;
}

a:hover {
  text-decoration: none;
  color: inherit;
  /* margin-left: 10px; */
}

main {
  display: flex;
  min-height: 78vh;
  max-height: 100vh;
  overflow: auto;
  justify-content: center;
  /* flex: 1; */
}

textarea {
  resize: none;
}

button {
  background-color: rgba(71, 212, 255, 1);
}

.globe_arrows button {
  background-color: white;
  border: none;
  border-radius: 50%;
  position: absolute;
  display: flex;
  padding: 5px;
}

.globe_buttons button {
  z-index: 200;
}

@media screen and (min-width: 320px) and (max-width: 425px) {
  main {
    min-height: 75vh;
  }
}
