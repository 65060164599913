.words_status {
  position: relative;
  border: solid 1px white;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
