.write-song-main {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.write-song-textarea {
  height: 50px;
}
