.search-card {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
}

.search-main {
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.search-main-content {
  padding-top: 5rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 45px;
  align-items: center;
  color: white;
  text-shadow: 2px 2px #000;
}

.search-main-content-bold {
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px #000;
}

.search-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 20rem;
  gap: 1rem;
}

.search-input-suggestions {
  position: relative;
}

.search-logo {
  position: absolute;
  height: 5rem;
}

.submit-button-search {
  border: none;
  border-radius: 25px;
  color: #fff;
  font-weight: 400;
  height: 49px;
  max-width: 100%;
  overflow: hidden;
  padding: 0 4px;
  position: absolute;
  right: 2.5px;
  top: -3.81rem;
  transition: all 0.5s ease;
  width: 8rem;
}

.button-text {
  position: absolute;
  top: 12px;
  left: 21px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 1;
}

.button-icon {
  transition: transform 0.5s ease, opacity 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  right: 30px;
  opacity: 0;
  visibility: hidden;
}

.submit-button-search:hover .button-text {
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
}

.submit-button-search:hover .button-icon {
  transform: translateX(-100%);
  opacity: 1;
  visibility: visible;
}

.submit-button-search:not(:hover) .button-text {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.submit-button-search:not(:hover) .button-icon {
  transform: translateX(0);
  opacity: 0;
  visibility: hidden;
}

.start-doing-rn {
  margin-top: 5rem;
  font-weight: bold;
  font-size: 20px;
}

.stumble-icon {
  color: white;
  size: 2px;
}

.freestyle-write-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}

.freestyle-write-buttons button {
  border: none;
  background-color: #ececec;
  border-width: 1px;
  border-radius: 28px;
  height: 55px;
  width: 148px;
  margin-top: 1rem;
  font-weight: bold;
}

.freestyle-write-buttons .freestyle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.freestyle-btn .btn-text {
  transition: transform 0.3s ease;
}

.freestyle-btn .btn-icon {
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 40px;
  opacity: 0;
  visibility: hidden;
}

.freestyle-btn:hover .btn-text {
  transform: translateX(-100%);
  visibility: hidden;
}

.freestyle-btn:hover .btn-icon {
  transform: translateX(-100%);
  opacity: 1;
  visibility: visible;
}

.rhyming-words {
  text-align: center;
  font-size: larger;
  font-weight: bolder;
  color: darkcyan;
}

.rhymingWord-search {
  border-radius: 33px;
  color: #000;
  box-shadow: 1px 1.3px #3e3e3ed6;
  cursor: pointer;
  font-size: 18px;
  height: 55px;
  width: 34rem;
  top: -4rem;
  border: none;
  position: relative;
}

.rhymingWord-search:focus {
  background-color: white;
  border: none;
}

.searchWord-search-suggestions {
  background: #cf732d;
  padding: 6px;
  width: 22rem;
  max-width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  font-weight: 90;
  font-size: 12pt;
}

.input-word {
  display: flex;
  justify-items: center;
  flex-direction: column;
}

.submit-button-rhyme {
  border-radius: 8px;
  width: 9rem;
  max-width: 100%;
  height: 2.7rem;
  margin-bottom: 1rem;
  font-weight: 300;
  color: #ffff;
  background: #f27312;
  border: 1px solid #f27312;
}

.stumble-button {
  border-radius: 25px;
  width: 9rem;
  height: 2.7rem;
  margin-top: 4rem;
  font-weight: 300;
  color: #ffff;
  background: #d75d00;
  border: 1px solid #d75d00;
  align-content: center;
}

.addmore-button-rhyme {
  font-weight: 100;
  font-size: 12px;
  background: transparent;
  border: none;
  opacity: 50%;
}

.Home-WOTD {
  text-align: center;
  color: rgb(16, 63, 61);
  font-weight: 700;
  margin-top: 4rem;
  font-size: 16px;
}

.WOTD {
  text-align: center;
  font-size: 20pt;
  font-weight: 600;
  font-family: "Lora", serif;
  background-image: -webkit-linear-gradient(
    left,
    #f00,
    #ff2b00,
    #f50,
    #ff8000,
    #fa0,
    #ffd500,
    #ff0,
    #d4ff00,
    #af0,
    #80ff00,
    #5f0,
    #2bff00,
    #0f0,
    #00ff2a,
    #0f5,
    #00ff80,
    #0fa,
    #00ffd5,
    #0ff,
    #00d5ff,
    #0af,
    #0080ff,
    #05f,
    #002aff,
    #00f,
    #2b00ff,
    #50f,
    #8000ff,
    #a0f,
    #d400ff,
    #f0f,
    #ff00d4,
    #f0a,
    #ff0080,
    #f05,
    #ff002b,
    #f00
  );
  -webkit-animation: animatedBackground_a 5s linear infinite alternate;
  -webkit-background-clip: text;
  -webkit-text-fill-color: #0000;
  background-clip: text;
}

@keyframes animatedBackground_a {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -500px 0;
  }
}

.before-submission {
  border: 1px solid rgb(29, 82, 64);
  border-radius: 10px;
}

.search-word {
  position: absolute;
}

.input-array {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 9px;
}

.remove-button {
  height: 1.5rem;
  display: contents;
  font-size: 1rem;
}

.search-suggestionsBox {
  background-color: white;
  border-radius: 33px;
  width: 540px;
  position: absolute;
  z-index: 1000;
  top: -9px;
}

.suggestions {
  text-align: center;
  color: black;
  cursor: pointer;
  border-right: 1px;
  border-left: 1px;
  border-bottom: 1px;
  position: relative;
}

.hover {
  background-color: #e7691b;
  border-radius: 33px;
}

.suggestions:hover {
  background-color: #e7691b;
  border-radius: 33px;
}

.stumble-image {
  height: 70px;
  cursor: pointer;
  margin-top: 2rem;
  width: 60.31px;
}

.search-words-phrases {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
}

.search-words-phrases:hover {
  cursor: pointer;
}

.home-words-phrases-button {
  background-color: transparent;
  border: 1px solid black;
  border-radius: 18px;
}

.search_words_grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  height: 240px;
  grid-gap: 5px;
}

.search_phrases_grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(3, 1fr);
  height: 160px;
  grid-gap: 5px;
}

.home_words_text {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  top: 5rem;
  position: relative;
}

.home_words_text strong {
  margin-bottom: 1rem;
}

.home_words_text button {
  color: black;
  background-color: #e3fbff;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.search_credits_timer {
  background-color: rgba(71, 212, 255, 1);
  border-radius: 0 18px 0 0;
  bottom: 45px;
  left: 0;
  padding: 10px;
  position: fixed;
  width: auto;
  z-index: 990;
}
.search_credits_timer:hover {
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .search-words-phrases {
    flex-direction: column;
    gap: 2rem;
  }

  .button-text {
    top: 5px;
  }

  .button-icon {
    top: 6px;
  }

  .home_words_text {
    top: 7rem;
    margin-bottom: 6rem;
  }

  .rhymingWord-search {
    top: -4rem;
  }
}
@media screen and (min-width: 480px) and (max-width: 768px) {
  .search-main-content {
    font-size: 60px;
  }

  .rhymingWord-search {
    height: 55px;
    width: 353px;
  }

  .submit-button-search {
    height: 50px;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .submit-button-search {
    height: 37px;
    width: 135px;
    font-size: 18px;
    padding-left: 1rem;
    top: -3.81rem;
  }

  .search-main-content {
    font-size: 28px;
  }

  .rhymingWord-search {
    width: 353px;
    height: 43px;
  }

  .freestyle-write-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .freestyle-write-buttons button {
    height: 42px;
    width: 300px;
  }

  .search-suggestionsBox {
    width: 350px;
    top: -0.8rem;
  }
}

@media screen and (min-width: 376px) and (max-width: 480px) {
  .start-doing-rn {
    margin-top: 8rem;
  }

  .search-content {
    margin-top: -7rem;
  }

  .search-main-content {
    font-size: 30px;
  }

  .stumble-icon {
    left: 6.5rem;
  }

  .search-words-phrases {
    margin-top: -8rem;
  }

  .rhymingWord-search {
    width: 353px;
    height: 43px;
    font-size: 18px;
    border: none;
    top: -4rem;
  }

  .home_words_text {
    top: 15rem;
  }

  .submit-button-search {
    height: 37px;
    width: 135px;
    font-size: 18px;
    top: -53px;
  }

  .search-logo {
    height: 5rem;
  }

  .stumble-button {
    margin-top: -1rem;
    margin-bottom: 10rem;
  }

  .freestyle-write-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .freestyle-write-buttons button {
    height: 42px;
    width: 300px;
  }

  .search-suggestionsBox {
    width: 350px;
    top: -0.8rem;
  }

  .button-text {
    top: 6px;
  }

  main {
    overflow-y: hidden;
    overflow-x: hidden;
    overflow: hidden;
  }
}


