.new_home {
  display: flex;
  z-index: 990;
  flex-direction: column;
  align-items: center;
}

.new_home_text {
  color: white;
  text-shadow: 2px 2px #000;
  font-size: 45px;
  padding: 10rem;
}

.new_home_button {
  border: none;
  font-size: 30px;
  color: white;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  animation: heartbeat 1.25s infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 3px rgba(71, 212, 255, 1);
  }
  20% {
    transform: scale(0.85);
    box-shadow: 0 0 20px rgb(115, 222, 255);
  }
  40% {
    transform: scale(0.75);
    box-shadow: 0 0 20px rgba(71, 212, 255, 1);
  }
  60% {
    transform: scale(0.85);
    box-shadow: 0 0 20px rgb(115, 222, 255);
  }
  80% {
    transform: scale(0.75);
    box-shadow: 0 0 3px rgba(71, 212, 255, 1);
  }
  100% {
    transform: scale(0.75);
    box-shadow: 0 0 20px rgb(115, 222, 255);
  }
}

@media screen and (max-width: 487px) {
  .new_home_text {
    font-size: 26px;
    padding: 4rem 0rem 6rem 0rem;
  }
}
