.login-label {
  margin-bottom: 1rem;
  display: block;
  font-style: italic;
}

.login-input {
  border-radius: 28px;
  border: none;
  height: 3.5rem;
  width: 27rem;
  max-width: 100%;
  background-color: white;
}

.login-input:active,
.login-input:focus {
  border-color: grey;
  box-shadow: none;
}

.password-icon {
  position: absolute;
  right: 10rem;
  top: 18rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .password-icon {
    right: 13.5rem;
    top: 18.4rem;
  }
}

@media screen and (max-width: 480px) {
  .login-input {
    width: 16rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .login-label {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    font-style: italic;
    justify-content: center;
    align-items: center;
  }

  .password-icon {
    right: 3.5rem;
    top: 14.6rem;
  }
}
