.Word-of-the-day {
  margin-top: 80px;
  font-size: larger;
  font-weight: bolder;
  color: rgb(19, 19, 19);
  text-align: center;
  position: relative;
}

.admin-main {
  display: flex;
  justify-content: center;
  position: relative;
}

.admin-card {
  display: flex;
  flex-direction: column;
  width: 32rem;
  height: auto;
  border-radius: 20px;
  background-color: rgb(255 255 255 / 70%);
  padding: 3.5rem 0 2.5rem 0;
}

.wordoftheday {
  border-radius: 8px;
  width: 22rem;
  max-width: 100%;
  background-color: white;
  color: rgb(121, 119, 119);
  margin-bottom: 2rem;
}
.admin-submit-button {
  border-radius: 8px;
  width: 14rem;
  max-width: 100%;
  height: 3rem;
  margin-bottom: 3rem;
  font-weight: 600;
  color: #ffff;
  background: #188fff;
  border: 1px solid #188fff;
}

.admin-logo {
  text-align: center;
}


@media screen and (max-width: 480px) {
  .admin-card {
    width: 21rem;
    height: auto;
  }

  .Word-of-the-day{
    width: 19rem;
    margin-top: 1rem;
    font-size: 20px;
  }

  .admin-logo{
    height: 9rem;
  }

  .wordoftheday{
    margin-top: 1rem ;
    width: 18rem;
  }

  .admin-submit-button{
    width: 9.5rem;
    height: 2.5rem;
  }
}
