.musicPlayer-main {
  z-index: 990;
}

.play-or-pause {
  background-color: transparent;
  border: none;
}

.player {
  align-items: center;
  background: #dcf2f3;
  bottom: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  height: 50px;
  justify-content: space-between;
  padding: 10px;
  position: fixed;
  width: 100%;
  z-index: 10000;
}

.backward-button {
  background-color: transparent;
  border: none;
}

.forward-button {
  background-color: transparent;
  border: none;
}

.music-player {
  display: flex;
  justify-content: center;
}

.music-dropdown {
  border: 1px solid #ddd;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: -5px;
  top: -82px;
}

.music-dropdown li {
  list-style: none;
  padding: 8px 12px;
  cursor: pointer;
}

.music-dropdown li:hover {
  background-color: #f6f6f6;
}

.genres-dropdown {
  background: transparent;
  border: none;
  border-radius: 100%;
  height: 44px;
  width: 44px;
}

.musicPlayer-app {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  position: relative;
}

.stumble-music-player {
  height: 40px;
  width: 40px;
  margin-left: 10px;
  transition: transform 0.1s ease-in-out;
}

.stumble-music-player:hover {
  cursor: pointer;
}

.genre-playing {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
}

.volume-slider {
  width: 100px;
  margin: 10px;
  cursor: pointer;
}
/* Styles for the slider thumb */
.volume-slider::-webkit-slider-thumb,
.volume-slider::-moz-range-thumb,
.volume-slider::-ms-thumb {
  -webkit-appearance: none;
  appearance: none;
  /* ... other styles ... */
  margin-top: 0px; /* Adjust this value to center the thumb vertically */
}

/* Styles for the slider track for Webkit browsers like Chrome and Safari */
.volume-slider::-webkit-slider-runnable-track {
  height: 10px;
  background: rgba(
    71,
    212,
    255,
    0.5
  ); /* Lighter blue as the default background */
  border-radius: 5px;
  border: none;
}

/* Styles for the slider track for Mozilla Firefox */
.volume-slider::-moz-range-track {
  height: 10px;
  background: rgba(
    71,
    212,
    255,
    0.5
  ); /* Lighter blue as the default background */
  border-radius: 5px;
  border: none;
}

/* For Microsoft Edge */
.volume-slider::-ms-track {
  height: 10px;
  background: rgba(
    71,
    212,
    255,
    0.5
  ); /* Lighter blue as the default background */
  border: none;
}

.player-buttons button {
  transition: transform 0.1s ease-in-out;
}

.player-buttons button:active {
  transform: scale(1.1);
}

.stumble-music-player:active {
  transform: scale(1.1);
}

.credits_button {
  border: none;
  border-radius: 8px;
  width: 120px;
  z-index: 990;
}

@media screen and (max-width: 487px) {
  .player {
    height: 42px;
  }

  .volume-slider {
    margin: 0px;
  }

  .genres-dropdown {
    height: 40px;
  }
  .credits_button {
    width: 85px;
  }
  .credits_timer_button {
    border: none;
    color: black;
    width: 110px;
    border-radius: 8px;
    font-size: 14px;
  }
}
