.badge-modal-user {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.modal-image {
  height: 100px;
  width: 100px;
  border: solid black 1px;
  padding: 6px;
  border-radius: 10px;
}

.badges-close-button {
  top: 10px;
  background-color: transparent;
  border: none;
  position: absolute;
  right: 12px;
  font-size: 20px;
}
