.add-button-as {
  border-radius: 8px;
  width: 6rem;
  max-width: 100%;
  font-weight: 600;
  height: 2rem;
  margin-top: 1rem;
  color: #fff;
  background: #f27312;
  border: 1px solid #f27312;
}

.sponge-bob-crying {
  height: 14rem;
  padding: 1rem;
}

.stumble-searchWord {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}
.after-search-logo > img {
  width: 160px;
  height: 60px;
  position: relative;
}

.rhymingWord-Aftersearch {
  border-radius: 33px;
  width: 646px;
  height: 55px;
  background-color: white;
  border: none;
}

.afterSearch-suggestionsBox {
  background-color: #fefefe;
  border-radius: 33px;
  position: absolute;
  z-index: 100;
  width: 635px;
}

.AfterSearch-suggestions {
  text-align: center;
  color: black;
  cursor: pointer;
  border-right: 1px;
  border-left: 1px;
  border-bottom: 1px;
  position: relative;
  z-index: 100;
}

.hover {
  background-color: #e7691b;
  border-radius: 33px;
}

.afterSearch-suggestionsBox :hover {
  background-color: #e7691b;
  border-radius: 33px;
}

.after-search-logo:hover {
  cursor: pointer;
}

.search-field {
  padding: 1rem 0;
}

.words-and-phrases {
  height: 490px;
  padding: 20px;
}

.words-and-phrases-buttons {
  padding: 6px;
  gap: 10px;
  margin-top: 5px;
}

.words-and-phrases-buttons button {
  border-radius: 33px;
  border-width: 1px;
  border-color: #d75d00;
  color: #d75d00;
  font-weight: 600;
  height: 3rem;
  width: 6rem;
  gap: 10px;
}

.aftersearch-content {
  display: flex;
  /* min-width: auto; */
  width: 800px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transform: none;
  height: 655px;
  border: solid 1px rgb(255 255 255);
  border-radius: 20px;
  background-color: rgb(255 255 255 / 70%);
  padding: 10px 0 0 0;
  position: relative;
}

.submit-button-rhyme {
  color: white;
  border-radius: 28px;
  font-weight: 600;
  /* margin-top: 1rem; */
  height: 38px;
  width: 60px;
  right: -252px;
  top: -40.3px;
  font-size: large;
  position: relative;
}

.submit-button-rhyme-before {
  color: black;
  font-weight: 600;
  margin-top: 10px;
  background-color: white;
  border: solid black 1px;
  height: 43px;
  min-width: 298px;
  border-radius: 100px;
  margin-bottom: 1rem;
}

.rhymingWord {
  border-radius: 28px;
  width: 316px;
  max-width: 100%;
  background-color: white;
  border: solid black 1px;
  color: black;
  height: 43px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12pt;
  margin-top: 10px;
}

.word-search {
  font-size: 25px;
}

.word-search:hover {
  cursor: pointer;
}

.word-search-selected {
  margin-bottom: 3rem;
  border-radius: 12px;
  padding: 6px;
  font-size: 25px;
  font: bold;
}

.word-search-selected:hover {
  cursor: pointer;
}

.words-button :focus {
  background-color: blue;
}

.search-results {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
  font-size: 19px;
  overflow-y: scroll;
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: grey; /* For Firefox, change the colors as needed */
  max-height: 400px;
  min-width: auto;
  max-width: 750px;
  background-color: white;
  border-radius: 0px 16px 16px 16px;
}

/* For Webkit browsers like Chrome, Safari */
.search-results::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.search-results::-webkit-scrollbar-track {
  background: lightgray; /* color of the tracking area */
}

.search-results::-webkit-scrollbar-thumb {
  background-color: grey; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid lightgray; /* creates padding around scroll thumb */
}
.addRhymes-and-generateSongs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 15px 40px;
}
.addRhymes-and-generateSongs-admin {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 15px 40px;
}
.title-word {
  color: #d75d00;
  font-size: 18px;
}

.container div {
  grid-column: span 1;
  grid-row: span 1;
}

.container div:nth-child(3n + 1) {
  grid-column: 1 / 2;
  grid-row: span 1;
}

.container div:nth-child(3n + 2) {
  grid-column: 2 / 3;
  grid-row: span 1;
}

.container div:nth-child(3n + 3) {
  grid-column: 3 / 4;
  grid-row: span 1;
}

.login-text-to-add {
  margin-top: 2rem;
}

.add-more-word {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.afterSearch-button-search {
  border: none;
  background-color: transparent;
}

.afterSearch-signup-button {
  border-radius: 10px;
  border: none;
  background-color: #188fff;
  color: white;
  padding: 0.5rem;
  width: 15rem;
  font-weight: 600;
}

.afterSearch-signup-button:hover {
  box-shadow: 5px 5px 5px lightblue;
}

.stumble-image-afterSearch {
  cursor: pointer;
  padding: 2px 20px;
  margin-left: 5px;
  border-radius: 18px;
  border: none;
  background: white;
}

.tabs-afterSearch-Phrases {
  border-top-right-radius: 18px;
}

.tabs-afterSearch-Words {
  border-top-right-radius: 18px;
}

.toggle-class {
  margin-top: 0px;
}
.music-player-afterSearch {
  display: flex;
  justify-content: center;
}
.phrases-button {
  margin-right: 5px;
}

.PHRASE {
  cursor: pointer;
}

.WORD {
  cursor: pointer;
}

.SLANT {
  cursor: pointer;
}

.blurphrases {
  color: transparent;
  text-shadow: 0 0 6px #000;
}

.blurText {
  position: relative;
  font-weight: 500;
}

.blurText:hover {
  cursor: pointer;
  filter: blur(100%);
}

.go-premium-text {
  display: flex;
}

.after-search-mainblur-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: blur(2px);
}
.after-search-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.search-result-for-text {
  color: grey;
}

.submit-button-afterSearch {
  border-radius: 25px;
  width: 8rem;
  max-width: 100%;
  height: 49px;
  font-weight: 400;
  color: #ffff;
  /* background: #f27312; */
  background: rgba(71, 212, 255, 1);
  /* border: 1px solid #f27312; */
  border: 1px solid rgba(71, 212, 255, 1);
  margin-left: -8.2rem;
  margin-top: 2.5px;
  padding: 0 4px 0 4px;
  overflow: hidden;
  transition: all 0.5s ease;
}

.submit-button-afterSearch .afterSearch-button-text {
  position: relative;
  top: 10px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  opacity: 1;
}

.submit-button-afterSearch .afterSearch-button-icon {
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  /* right: 27.8rem; */
  /* left: 10px; */
  opacity: 0;
  visibility: hidden;
}

.submit-button-afterSearch:hover .afterSearch-button-text {
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
}

.submit-button-afterSearch:hover .afterSearch-button-icon {
  /* transform: translateX(-100%); */
  opacity: 1;
  visibility: visible;
}

.submit-button-afterSearch:not(:hover) .afterSearch-button-text {
  transform: translateX(0);
  opacity: 1;
  visibility: visible;
}

.submit-button-afterSearch:not(:hover) .afterSearch-button-icon {
  transform: translateX(0);
  opacity: 0;
  visibility: hidden;
}

.search-results-for-param {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
}

.upgrade-text {
  display: flex;
}

.search-results button {
  background: white;
  border: none;
  width: 215px;
  position: relative;
  border-radius: 29px;
  right: 70%;
  bottom: 100%;
}
.afterSearch-arrows-results {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0rem;
}

.afterSearch-rightArrow button {
  background: transparent;
  border: none;
  margin-left: 0rem;
}

.afterSearch-leftArrow button {
  background: transparent;
  border: none;
}

.afterSearch-loader {
  height: 480px;
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AfterSearch-editButton {
  z-index: 990;
}

.afterSearch_category_box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.category_deselect_button {
  border: none;
  height: 20px;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
}

@media screen and (min-width: 817px) {
  .words-and-phrases {
    min-width: 700px;
  }
}
@media screen and (min-width: 768px) {
  .search-results > div {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 768px) {
  .search-results {
    grid-template-columns: repeat(2, 1fr);
  }
  .container div:nth-child(2n + 1) {
    grid-column: 1 / 2;
    grid-row: span 1;
  }

  .container div:nth-child(2n + 2) {
    grid-column: 2 / 3;
    grid-row: span 1;
  }
}
@media screen and (min-width: 481px) {
  .no-rhymes-search-text {
    margin-bottom: 2rem;
  }
}

.AddMore-form {
  z-index: 200;
}

@media screen and (max-width: 480px) {
  .submit-button-afterSearch .afterSearch-button-text {
    top: 5px;
  }
  .stumble-image-afterSearch {
    padding: 10px 20px;
  }
  .submit-button-afterSearch {
    height: 36.75px;
  }
  .rhymingWord-Aftersearch {
    width: 353px;
    font-size: 17px;
    height: 42px;
  }
  .submit-button-song {
    height: 42px !important;
    width: 200px !important;
    margin-top: 0rem !important;
    margin-bottom: 1rem;
  }

  .addRhymes-and-generateSongs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0rem;
    /* margin-top: 13rem; */
  }
  .after-search-logo {
    z-index: 100;
  }

  .submit-button-rhyme-before {
    height: 42px;
    width: 305px;
  }
  /* .stumble-image-afterSearch {
    height: 37px;
    width: 129px;
    margin-right: 25%;
    margin-top: 20px;
  } */

  .search-results-for-param {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .toggle-label {
    margin-bottom: 5px !important;
  }
  .words-and-phrases-buttons {
    margin-top: 1rem;
  }

  .aftersearch-content {
    max-width: 393px;
    min-width: 393px;
    height: auto;
  }

  .search-results {
    grid-gap: 2px;
    padding: 5px;
    font-size: 14px;
    max-height: 316px;
    min-width: 300px;
    max-width: 350px;
  }

  .title-word {
    font-size: 16px;
  }

  .word-search {
    font-size: 17px;
  }
  .words-and-phrases-buttons button {
    height: 30px;
    font-size: 14px;
    text-align: center;
    margin-right: 14px;
  }

  .words-and-phrases {
    /* max-height: 10rem;
    min-height: 10rem; */
    width: 100%;
    max-height: 653.71px;
    padding: 0px;
    height: 370px;
  }

  .words-and-phrases-buttons {
    margin-left: 15px;
  }

  .submit-button-rhyme {
    width: 6.2rem;
    height: 2.5rem;
    right: -214px;
    top: -39px;
  }

  .afterSearch-suggestionsBox {
    width: 350px;
  }

  #floatingInputAddMore {
    height: 42px;
    width: 305px;
    margin-top: 1rem;
  }

  main {
    overflow-y: hidden;
  }

  .addRhymes-and-generateSongs-admin {
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }
}
.no-rhymes-search {
  margin-top: 5rem;
}
@media screen and (min-width: 481px) and (max-width: 816px) {
  .aftersearch-content {
    min-width: 30rem;
  }
}
.wordDiv {
  min-height: 300px;
  width: 250px;
  background-color: white;
  position: absolute;
  right: 10px;
  top: 210px;
  border-radius: 20px;
  text-align: center;
  padding: 10px;
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 20px;
}
.submit-button-song {
  color: white;
  font-weight: 600;
  /* background-color: #f27312; */
  background-color: rgba(71, 212, 255, 1);
  border: none;
  height: 48px;
  width: 230px;
  font-size: 17px;
  border-radius: 100px;
  /* margin-top: 10px; */
}
.songDiv {
  height: 250px;
  overflow-y: scroll;
  text-align: left;
}
.songDiv > ul {
  color: olive;
  font-weight: 400;
}
.closebtn {
  text-align: center;
  float: right;
  cursor: pointer;
  font-size: 12px;
  vertical-align: text-bottom;
  padding-right: 5px;
  padding-top: 5px;
}
.username_input {
  border-radius: 8px;
  width: 95%;
  background-color: white;
  color: black;
  font-weight: 400;
  font-size: 12pt;
  margin-top: 10px;
}
.array_words {
  background-color: lightcyan;
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  border-radius: 10px;
  margin-right: 1px;
  margin-left: 1px;
  font-weight: 500;
}
.btn_br {
  margin-left: 10px;
}

.afterSearch_credits_timer {
  position: sticky;
  bottom: 2rem;
  background-color: white;
  border-radius: 18px;
  padding: 10px;
  right: 90rem !important;
}

.afterSearch_credits_timer:hover {
  cursor: pointer;
}

@media screen and (max-width: 375px) {
  .aftersearch-content {
    min-width: 370px;
    max-width: 370px;
  }

  .search-results {
    min-width: auto;
  }

  .afterSearch-suggestionsBox {
    width: 350px;
  }
}

@media screen and (max-width: 320px) {
  .aftersearch-content {
    min-width: 315px;
    max-width: 315px;
  }

  .search-results {
    min-width: auto;
  }

  .rhymingWord-Aftersearch {
    width: 300px;
  }
  .word-search {
    font-size: 15px;
  }

  .afterSearch-suggestionsBox {
    width: 300px;
  }
}
