.Navbar {
  background: #dcf2f3;
  padding: 10px 20px 10px 20px;
  height: 50px;
  display: flex;
  align-items: center;
  align-content: center;
  z-index: 1000;
}
.navbar-brand {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-left: -26px;
}

#nav-title {
  color: black;
}

.NavLink {
  font-size: 20px;
  font-weight: 500;
  color: black;
}

#Home {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}

#about-us {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}

#about-us:hover {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}

#admin {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}
#song {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}
#accept-or-reject {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}
#logout {
  border: solid black;
  font-size: 18px;
  font-weight: 500;
  padding: 5px;
  width: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 1.5px;
  border-radius: 18px;
}
#add-words {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}
#Home:hover {
  font-style: Bold;
  color: #a1def4;
}

#profile {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}

#translator {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}

#games {
  font-size: 17px;
  font-weight: 500;
  color: grey;
}

#games:hover {
  font-style: Bold;
  color: #a1def4;
}

#profile:hover {
  font-style: Bold;
  color: #a1def4;
}

#translator:hover {
  font-style: Bold;
  color: #a1def4;
}

#admin:hover {
  font-style: Bold;
  color: #a1def4;
}
#song:hover {
  font-style: Bold;
  color: #a1def4;
}
#accept-or-reject:hover {
  font-style: Bold;
  color: #a1def4;
}
#logout:hover {
  font-style: Bold;
  color: #a1def4;
}
#add-words:hover {
  font-style: Bold;
  color: #a1def4;
}

#Sign-up {
  border: solid black;
  font-size: 16px;
  font-weight: 500;
  padding: 5px;
  width: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-width: 1.5px;
  border-radius: 18px;
}

.navbar-expand-xl .navbar-nav {
  gap: 1rem;
  display: flex;
  align-items: center;
}

.pe-3 {
  padding-right: 2rem !important;
}

@media screen and (max-width: 480px) {
  .nav-title-bl {
    font-size: 18px;
    padding-top: 4rem;
    color: black;
    font-weight: bold;
  }

  .Navbar {
    height: 59px;
  }
}
