.tc-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  z-index: 1;
}

.tc-body {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.51);
  border-radius: 20px;
  height: 670px;
  width: 604px;
  align-items: center;
  padding: 10px;
  position: relative;
}

.tc-swap-button {
  background-color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 35px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  left: 10px;
  position: absolute;
}

.tc-character-img {
  height: 430px;
  border-radius: 28px;
}

.tc-body p {
  font-size: 30px;
  font-weight: 700;
}

.tc-recorder-button {
  background-color: transparent;
  border: none;
}

.recorder-listening {
  height: 105px;
  color: red;
  animation: pulsate 1.2s infinite;
}

.recorder-not-listening {
  height: 105px;
  color: red;
}

.tc-audio-mic{
  border: none;
  background-color: transparent;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 786px) {

  .tc-body{
    height: 560px;
    width: 560px;
  }

  .tc-character-img{
    height: 350px;
  }
}

@media screen and (max-width:487px) {
  .tc-body{
    height: 490px;
    width: 300px;
  }

  .tc-character-img{
    height: 260px;
  }

  .tc-swap-button{
    font-size: 13px;
  }

  .tc-body p{
    font-size: 24px;
  }

  .recorder-not-listening{
    height: 80px;
  }

  .recorder-listening{
    height: 80px;
  }
}
