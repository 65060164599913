.translator-main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  z-index: 1;
}

.translator-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgb(255 255 255 / 70%);
  padding: 3.5rem 3rem 2.5rem 3rem;
}

.translator-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10rem;
}

.right {
  flex: 1;
}

.left {
  flex: 3;
}

.result-box-translator {
  width: 16rem;
  height: 18rem;
  border: none;
  border-radius: 18px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-box-translator textarea {
  height: 18rem;
  width: 16rem;
  border: none;
  border-radius: 18px;
  margin-top: 0rem;
}

.input-box-translator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16rem;
  height: 18rem;
  border: none;
  border-radius: 18px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.input-box-translator::placeholder {
  padding-top: 50%;
  padding-left: 25%;
}

.translator-icon img {
  height: 4rem;
}

.translator-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.translate-button button {
  background: #f27312;
  border: none;
  height: 2.5rem;
  width: 6rem;
  border-radius: 18px;
  font-size: 17px;
  font-weight: 600;
}
