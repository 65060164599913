.signup-textarea {
  height: 300px;
  margin-bottom: 10px;
  padding: 10px;
}

.editSong-main {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.editSong-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255 255 255 / 51%);
  width: 1068px;
  /* height: 760px; */
  max-width: 100%;
  bottom: -50px;
  border-radius: 20px;
  background-color: rgb(255 255 255 / 51%);
  padding: 1.5rem 0 2.5rem 0;
  position: relative;
}

.editSong-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.editSong-form textarea {
  height: 590px;
  width: 800px;
  font-size: larger;
  text-align: center;
}

.editSong-titleBox {
  border: solid black 1px;
  height: 47px;
  width: 350px;
  max-width: 100%;
  border-radius: 28px;
}
/* .signup-card {
  min-height: 45rem;
} */
.cancel_btn {
  background-color: gray;
  width: 150px;
  height: 3rem;
  text-align: center;
  align-self: center;
}
.btn_row {
  display: flex;
  text-align: center;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}
.update-song-button {
  width: 150px;
  border: none;
  border-radius: 16px;
  height: 47px;
  color: #d75d00;
  background: white;
  box-shadow: 0 1px 1px 0 black;
  /* margin-top: 2rem; */
}

.regenerateSong-button {
  width: 150px;
  border: none;
  border-radius: 16px;
  height: 47px;
  /* background: #d75d00; */
  box-shadow: 0 1px 1px 0 black;
  color: white;
}
.refresh_icon {
  align-self: end;
  margin-right: 5px;
  margin-bottom: 2px;
  color: rgba(71, 212, 255, 1);
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 487px) {
  .editSong-card {
    width: 380px;
    bottom: -20px;
  }

  .editSong-form textarea {
    width: 345px;
  }
}
