.chatbot-main {
  display: flex;
  flex-direction: column;
  height: 77vh;
  z-index: 1;
}

.chatbot-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1px solid gray;
  margin-left: -16px;
}

.chatbot-main li:hover {
  cursor: pointer;
}
.character-chatting {
  height: 90px;
}

.chatbot-messages {
  display: flex;
  flex-direction: column;
}

.rap-messages {
  padding: 5px;
  font-size: 14px;
  width: 100%;
  display: grid;
  max-height: calc(100vh - 350px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 1rem;
  scrollbar-width: thin; /* for Firefox */
  scrollbar-color: transparent transparent; /* for Firefox */
}

.rap-messages::-webkit-scrollbar {
  width: 8px; /* for Chrome, Safari, and Opera */
}

.rap-messages::-webkit-scrollbar-thumb {
  background-color: transparent; /* for Chrome, Safari, and Opera */
}
.chatBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}

.chatBox-main {
  display: flex;
  justify-content: center;
}

.chatBox-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.chatBox-textareaWrapper {
  position: relative;
  display: inline-block;
}

.chatBox-form textarea {
  min-width: 80vw;
  border-radius: 18px;
  border: solid rgba(71, 212, 255, 1) 1px;
  padding: 10px;
  padding-right: 100px;
}

.chatBox-form button {
  border: none;
  height: 37px;
  /* color: rgba(71, 212, 255, 1); */
  transform: translateY(-50%);
}

.chatbox-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0rem;
  width: 6rem;
  position: absolute;
  right: 3px;
  top: 45%;
}

.chatBox-form button:hover {
  /* color: rgba(71, 212, 255, 1); */
  /* background-color: transparent; */
}

.character-chat {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.character-icon {
  height: 50px;
}

.user-chat {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.character-message {
  background-color: white;
  border: solid black 0.1px;
  border-radius: 28px;
  padding: 10px 25px;
  max-width: 75%;
  margin-bottom: 2rem;
}

.user-message {
  background-color: rgba(255, 224, 176, 1);
  border: solid black 0.1px;
  padding: 10px 20px;
  max-width: 75%;
  border-radius: 28px;
}

ul {
  list-style-type: none;
  padding-left: 4px;
}

/* Use the ::before pseudo-element to add custom images */
li.harry::before,
li.snoop::before,
li.shakespeare::before,
li.cleopatra::before {
  content: "";
  display: inline-block;
  width: 40px; /* Adjust as needed */
  height: 40px; /* Adjust as needed */
  margin-right: 10px; /* Space between the image and the text */
  vertical-align: middle; /* To align the image with the text */
}

li.harry::before {
  background: url("../../assets/images/harryPotter.png") no-repeat center center /
    contain;
}

li.snoop::before {
  background: url("../../assets/images/snoopDogg.png") no-repeat center center /
    contain;
}

li.shakespeare::before {
  background: url("../../assets/images/Shakespeare.png") no-repeat center center /
    contain;
}

li.cleopatra::before {
  background: url("../../assets/images/Cleopatra.png") no-repeat center center /
    contain;
}

li.harry:hover,
li.snoop:hover,
li.shakespeare:hover,
li.cleopatra:hover {
  cursor: pointer;
}

.character-message button {
  background: none;
  border: none;
}

.typewriter {
  overflow: hidden;
  white-space: pre-wrap;
  /* border-right: .15em solid; */
  animation: typing 3s steps(40, end), blink-caret .5s step-end infinite;
}

.dots-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #3498db;
  border-radius: 50%;
  animation: bounce 1s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

.chatBot-swap-button {
  background-color: white;
  border: none;
  font-size: 16px;
  font-weight: 600;
  border-radius: 35px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
}

.chatBox-loading-dots {
  position: absolute;
  right: 2rem;
  bottom: 13rem;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: inherit; }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media screen and (max-width: 486px) {
  ul {
    padding-left: 0;
  }
}
