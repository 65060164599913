.login-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.login-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px rgb(255 255 255);
  width: 718px;
  height: 559px;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 3.5rem 0 2.5rem 0;
  position: relative;
}

.login-card label {
  margin-left: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}

.login-button {
  border-radius: 28px;
  width: 27rem;
  max-width: 100%;
  height: 3rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-weight: 600;
  color: #fff;
  /* background: #f27312; */
  border: none;
}

.login-google {
  position: relative;
  color: #0af;
}

.login-button:hover {
  /* background: #f27312; */
  border: none;
}

.login-input:active,
.login-input:focus {
  /* border-color: #f27312; */
  box-shadow: none;
}

#text-1 {
  font-size: 25pt;
  font-weight: 700;
}

@keyframes animatedBackground_a {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -500px 0;
  }
}

#text-2 {
  font-family: "Noto Sans", sans-serif;
  font-size: 21px;
  margin: 15px 0 40px 0;
  color: #867373;
}

#text-3 {
  display: flex;
  justify-content: center;
}

#text-4 {
  padding-left: 15px;
  color: black;
  text-decoration: underline;
}

#text-4:hover {
  cursor: pointer;
  font-weight: 600;
  color: black;
}

#text-5 {
  font-family: "Noto Sans", sans-serif;
  font-size: 17.5px;
}
#text-5 strong {
  color: rgba(71, 212, 255, 1);
}

#text-5 strong:hover {
  cursor: pointer;
  color: rgba(71, 212, 255, 1);
}

@media screen and (max-width: 480px) {
  .login-button {
    width: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    height: 2.3rem;
  }

  #text-1 {
    font-size: 18px;
  }

  #text-2 {
    font-size: 14px;
  }

  #text-3 {
    font-size: 14px;
  }

  #text-4 {
    margin-left: 0rem;
    font-size: 14px;
  }

  #text-5 {
    font-size: 14px;
  }

  .login-card {
    width: 20rem;
    height: 27rem;
    display: flex;
    align-items: center;
    padding: 2.5rem 0 2.5rem 0;
    margin-top: 3.5rem;
    justify-content: center;
  }
}
