.css-1wnsr1i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 400px; */
  background-color: #fff;
  border-radius: 28px;
  /* border: 2px solid #000; */
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 32px;
}

.add-moreWord-button {
  border: none;
  /* background-color: #f27312; */
  border-radius: 18px;
  color: white;
  width: 131px;
  height: 40px;
  margin-left: -8.5rem;
  margin-top: 0.3rem;
}

.username_input_pop {
  border-radius: 50px;
  min-width: 554px;
  height: 48px;
  background-color: white;
  color: black;
  font-weight: 400;
  font-size: 12pt;
  /* margin: 20px; */
  border: solid grey 1px;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.close-button-gptModal {
  border: none;
  background: none;
  position: absolute;
  right: 1rem;
  font-size: 1.5rem;
}

.generatedSong-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.generatedSong-header button {
  background: none;
  border: none;
  font-size: 400;
  font-size: x-large;
  /* font-weight: bold; */
  position: relative;
  /* right: -96px; */
  top: -30px;
  left: -255px;
}

.modal-backdrop.show {
  opacity: 0;
}
.modal-backdrop {
  height: 500px;
}
.wordPopDiv {
  min-height: 340px;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.clear-button-gptModal {
  float: right;
  background-color: white;
  border: solid black 1px;
  border-radius: 40px;
  height: 48px;
  width: 176px;
}

.submit-button-song-pop {
  color: white;
  font-weight: 600;
  /* background-color: #f27312; */
  border: none;
  height: 48px;
  width: 275px;
  border-radius: 40px;
  float: left;
  padding: 0px 10px;
}
.songDivPop {
  height: 250px;
  overflow-y: scroll;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
}
.songDivPop > ul {
  /* list-style: none; */
  color: olive;
  font-weight: 400;
}
.closebtnPop {
  cursor: pointer;
  font-size: 12px;
  padding-left: 10px;
}

.array_words_pop {
  background-color: lavenderblush;
  -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear;
  transition: background-color 1000ms linear;
  border-radius: 10px;
  margin-right: 1px;
  margin-left: 1px;
}
.modal .modal-content {
  width: 100% !important;
  border-radius: 20px;
}
.modal_heading {
  font-size: 20px;
  margin-top: 1rem;
  text-align: center;
  color: darkslategrey;
}

.selected-words-gptModal {
  display: flex;
  background: rgb(220, 220, 250);
  height: 25px;
  width: auto;
  border-radius: 18px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.modal_no_song {
  text-align: center;
  padding-top: 50px;
  height: 200px;
  color: red;
  font-size: 20px;
}
.ok_btn {
  color: white;
  font-weight: 600;
  background-color: #f27312;
  border: none;
  height: 2.5rem;
  min-width: 8rem;
  border-radius: 8px;
  margin-top: 20px;
}
.generated_song_div {
  border: 2px solid lavenderblush;
  margin-top: 15px;
  margin-bottom: 15px;
  height: 585px;
  padding: 15px;
  border-radius: 3px;
  font-size: 17px;
  overflow-y: scroll;
  /* color: darkgoldenrod; */
  font-weight: 400;
  transition: border 500ms ease-out;
}
.display_none {
  display: none;
}
.display_block {
  display: block;
  text-align: center;
}

.song_geneated_saveButton {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
#song_text > textarea {
  height: 300px;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 17px;
  /* color: darkgoldenrod; */
  font-weight: 400;
  border: 2px solid gray;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}
.lyric_text {
  height: 230px;
  width: 100%;
  padding: 15px;
  border-radius: 3px;
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 17px;
  /* color: darkgoldenrod; */
  font-weight: 400;
  border: 2px solid gray;
  -webkit-transition: border 500ms ease-out;
  -moz-transition: border 500ms ease-out;
  -o-transition: border 500ms ease-out;
  transition: border 500ms ease-out;
}
.modal {
  z-index: 1000 !important;
}

.clear_btn,
.clear_btn:hover {
  float: right;
  border: none;
  color: red;
  font-size: 14px;
  font-weight: 500;
}
.clear_btn:hover {
  color: red !important;
}

.modal .modal-content {
  max-width: 100% !important;
}

.generated_song_div_loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.edit-icon {
  float: left;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
}
.goback_icon {
  font-size: 16px;
  /* color: darkgoldenrod; */
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 20px;
}

.songs-type-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.songs-type-buttons button {
  /* background-color: #f27312; */
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 8px;
  outline: none;
}

.modal-backdrop {
  z-index: 1 !important;
}
.btn_mr {
  margin-right: 10px;
}
.rap-button-clicked {
  outline: none;
  box-shadow: 0 6px 18px 0 #f27312, 0 4px 16px 0 #f27312;
}

.country-button-clicked {
  outline: none;
  box-shadow: 0 6px 18px 0 #f27312, 0 4px 16px 0 #f27312;
}

.pop-button-clicked {
  outline: none;
  box-shadow: 0 6px 18px 0 #f27312, 0 4px 16px 0 #f27312;
}

.ballad-button-clicked {
  outline: none;
  box-shadow: 0 6px 18px 0 #f27312, 0 4px 16px 0 #f27312;
}

.songs-type-buttons button {
  -webkit-transform: translateZ(0);
}

.cross-button {
  background: none;
  border: none;
  right: 8px;
  position: absolute;
  top: 1rem;
  font-size: 18px;
}

.modal-ending-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.gptModal-grid {
  max-height: 240px;
  overflow-y: scroll;
  min-width: 555px;
  margin-top: 2rem;
}

.editSong-back-button {
  background: none;
  border: none;
}

.select-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin: 20px;
}

.style-button {
  margin-right: 5px;
}

.select-legend {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  margin-left: 20px;
}

.legends-image {
  height: 30px;
}

.legends-button {
  margin-right: 5px;
}

.editable_song {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.song_generated_container i {
  opacity: 0;
  font-size: 20px;
  color: #1f1e1e;
  will-change: transform;
  -webkit-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.song_generated_btn_wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  width: 275px;
  height: 48px;
  background-color: #eeeeed;
  border-radius: 80px;
  padding: 0 18px;
  will-change: transform;
  transition: all 0.2s ease-in-out;
}

.song_generated_btn_wrap:hover {
  /* transition-delay: .4s; */
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.song_generated_btn_wrap span {
  position: absolute;
  z-index: 99;
  width: 275px;
  height: 48px;
  border-radius: 28px;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  font-size: 18px;
  text-align: center;
  line-height: 45px;
  /* letter-spacing: 2px; */
  color: #eeeeed;
  background-color: #1f1e1e;
  padding: 0 18px;
  transition: all 1.2s ease;
}

.song_generated_container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 240px;
  height: 64px;
  border-radius: 80px;
}

.song_generated_container i:nth-of-type(1) {
  -webkit-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.song_generated_container i:nth-of-type(2) {
  -webkit-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.song_generated_container i:nth-of-type(3) {
  -webkit-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.song_generated_container i:nth-of-type(4) {
  -webkit-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.song_generated_container i:nth-of-type(5) {
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.song_generated_btn_wrap:hover span {
  -webkit-transition-delay: 0.25s;
  transition-delay: 0.25s;
  -webkit-transform: translateX(-280px);
  transform: translateX(-280px);
}

.song_generated_btn_wrap:hover i {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

@media screen and (max-width: 480px) {
  .gptModal-grid {
    min-width: 300px;
    margin-top: 1rem;
  }
  .songs-type-buttons button {
    font-size: 12px;
  }
  .songs-type-buttons button:focus,
  .songs-type-buttons button:active {
    outline: none;
    box-shadow: 0 6px 18px 0 #f27312, 0 4px 16px 0 #f27312;
  }

  .username_input_pop {
    min-width: 300px;
    font-size: 14px;
    height: 42px;
    margin: 0px;
    margin-bottom: 1rem;
  }

  .add-moreWord-button {
    margin-left: -7.4rem;
    margin-top: 3px;
    width: 100px;
    height: 36.75px;
  }

  .modal-ending-buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .submit-button-song-pop {
    height: 48px;
    /* margin-top: 1rem; */
  }

  .clear-button-gptModal {
    width: auto;
    height: 42px;
  }
  .modal-body {
    height: 565px;
  }

  .select-style {
    padding: 5px;
    margin-left: 10px;
  }

  .select-legend {
    margin-left: 10px;
  }

  .modal_heading {
    margin-top: 0rem;
  }

  .wordPopDiv {
    padding-top: 10px;
    min-height: 265px;
  }

  .song_rows {
    max-height: 405px;
    overflow-y: auto;
  }
  .generated_song_div {
    height: 435px;
  }

  .generatedSong-header button {
    left: -30px;
  }

  .song_geneated_saveButton {
    align-items: center;
    gap: 30px;
  }
}
.song_last_line {
  font-weight: bold;
  color: darkblue;
}

.editable_song_textfield {
  max-height: 580px;
  overflow-y: auto;
  text-align: center;
}

.generatedRap-parag > p {
  margin-bottom: 5px;
}

.Toastify__toast--centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure it's on top */
}

.rapModal_selected_words {
  background-color: transparent;
  border: solid 1px black;
  border-radius: 18px;
  padding: 4px 8px;
  display: flex;
}

.rapModal_selected_words span {
  position: relative;
  top: -7px;
}

@media screen and (max-width: 487px) {
  .editable_song_textfield {
    max-height: 450px;
  }
}
@media screen and (min-width: 481px) and (max-width: 768px) {
  .username_input_pop {
    min-width: 450px;
  }

  .add-moreWord-button {
    margin-left: -10rem;
  }

  .submit-button-song-pop {
    width: 260px;
  }

  .clear-button-gptModal {
    /* width: 100px; */
  }
}

@media screen and (max-width: 320px) {
  .username_input_pop {
    min-width: 290px;
  }
}
