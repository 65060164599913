.details-updation-fields {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font: black;
}

.user-details-input {
  border: solid black 1px;
  border-radius: 28px;
  background-color: rgb(243, 243, 243);
  width: 347px;
  height: 47px;
  padding: 16px;
}

.user-updation-username {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.user-updation-email {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.user-details-update-button {
  /* background-color: #f27312; */
  border: none;
  border-radius: 28px;
  color: white;
  width: 151px;
  height: 47px;
  font-weight: 500;
  margin-top: 3rem;
}

@media screen and (max-width: 480px) {
  .user-updation-username {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .user-updation-email {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .user-details-input {
    width: 305px;
  }

  .user-details-update-button {
    width: 305px;
  }
}
