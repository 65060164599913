.plans {
  color: black;
  background-color: white;
  height: 400px;
  padding: 10px;
  border-radius: 15px;
  width: 400px;
  text-align: center;
  display: table;
  box-shadow: 1px 2px 10px 4px lightgray;
}

.plans_body {
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
}

.plans_col {
  width: 300px;
}
.plans_main {
  background-color: hsla(0, 0%, 100%, 0.7);
  border-radius: 20px;
  height: 655px;
  width: 800px;
  display: flex;
  padding: 3.5rem 10px 2.5rem;
  flex-direction: column;
  align-items: center;
}
.checkout_btn {
  /* float: right; */
  border: none;
  border-radius: 18px;
  height: 57px;
  width: 122px;
  color: white;
}
.plans_div {
  height: auto;
  display: table-cell;
  vertical-align: middle;
}
.price_h {
  color: black;
}

@media screen and (max-width: 487px) {
  .plans_main {
    width: 310px;
    height: 430px;
  }

  .plans {
    width: 275px;
    height: 350px;
  }
}
