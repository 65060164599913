.signup-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.signup-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 718px;
  height: 559px;
  border-radius: 20px;
  background-color: rgb(255 255 255 / 51%);
  padding: 3.5rem 0 2.5rem 0;
  position: relative;
}
.password-icon-signup {
  position: absolute;
  cursor: pointer;
  right: 10rem;
  top: 17.5rem;
}

.signup-label {
  margin-bottom: 1rem;
  display: block;
  font-style: italic;
}

.signup-input {
  border-radius: 28px;
  max-width: 100%;
  background-color: white;
  width: 28rem;
  height: 3.5rem;
  border: none;
  margin-bottom: 0.5rem;
}

.signup-input:active,
.signup-input:focus {
  border-color: white;
  outline: none;
  box-shadow: none;
}
.signup-width {
  width: 14.5rem;
}
.signup-details {
  display: flex;
  justify-content: center;
}

.signup-form label {
  margin-right: 19rem;
  margin-bottom: 0.5rem;
}

.signup-button {
  border-radius: 28px;
  width: 28rem;
  max-width: 100%;
  height: 3rem;
  font-weight: 600;
  color: #fff;
  /* background: #f27312; */
  border: none;
  margin-top: 2rem;
}

.s-password-text {
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.s-statement {
  color: rgb(108 117 125) !important;
  margin-bottom: 2rem;
}

.s-statement strong {
  color: rgba(71, 212, 255, 1);
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#s-text-1 {
  font-size: 25pt;
  font-weight: 700;
  padding: 1rem;
}

#s-text-5 {
  font-family: "Noto Sans", sans-serif;
  font-size: 17.5px;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}
#s-text-5 strong {
  color: rgba(71, 212, 255, 1);
}

#s-text-5 strong:hover {
  cursor: pointer;
  color: rgba(71, 212, 255, 1);
}

@media screen and (max-width: 768px) {
  .password-icon-signup {
    right: 13rem;
    top: 17.8rem;
  }
}

@media screen and (max-width: 480px) {
  .signup-card {
    width: 20rem;
    height: 31rem;
    display: flex;
    justify-content: center;
  }

  .signup-button {
    width: 16rem;
    height: 2.5rem;
  }
  #s-text-1 {
    font-size: 18px;
  }

  .s-password-text {
    font-size: 10px;
  }
  .s-statement {
    font-size: 12px;
    margin-top: 0.5rem;
  }

  .signup-input {
    width: 16rem;
  }

  #s-text-5 {
    font-size: 14px;
    margin-top: 0rem;
  }

  .signup-form label {
    margin-right: 7rem;
  }

  .password-icon-signup {
    right: 3rem;
    top: 17.8rem;
  }
}
