.profile-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  z-index: 1;
}
.profile-main button {
  float: right;
}

.profile-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6rem;
  font-weight: 600;
}
.profile-user-details {
  align-items: center;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 20px;
  display: flex;
  font-weight: 400;
  padding: 20px;
  gap: 4rem;
  /* height: 126px; */
  position: relative;
  flex-direction: row;
  justify-content: space-between;
}

.profile-user-update-button {
  background-color: white;
  border: solid black 1px;
  border-radius: 28px;
  min-width: 128px;
  height: 47px;
}
.profile-password-change {
  position: relative;
  border: solid 1px white;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.profile-password {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8rem;
  font-weight: 600;
}

.profile-password-change-button {
  background-color: white;
  border: solid black 1px;
  border-radius: 28px;
  height: 47px;
  width: 180px;
}

.profile-membership-details {
  position: relative;
  border: solid 1px white;
  border-radius: 20px;
  background-color: white;
  padding: 20px;
  display: flex;
  /* height: 86px; */
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.profile-membership {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6rem;
  font-weight: 600;
}

.profile-membership-update-button {
  background-color: white;
  border: solid black 1px;
  border-radius: 28px;
  height: 47px;
  width: 134px;
  float: right;
}

.profile-user-badges {
  position: relative;
  border: solid 1px white;
  border-radius: 20px;
  background-color: white;
  padding: 1rem 2rem;
  justify-content: space-between;
  display: flex;
  gap: 1rem;
  /* height: 126px; */
  flex-direction: column;
  font-weight: 600;
}

.profile-user-badge {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 2rem;
}

.profile-user-badge img {
  height: 43.9px;
}

.profile-user-badge img:hover {
  cursor: pointer;
}

.profile-name {
  margin-top: 0;
}

.FirstAccepted {
  background-color: none;
  background: none;
  border: none;
  margin-left: 2rem;
}

.FirstAcceptedImage {
  display: flex;
  height: 6rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 18px;
}

.FirstRejected {
  background-color: none;
  background: none;
  border: none;
  margin-left: 2rem;
}

.FirstRejectedImage {
  display: flex;
  height: 6rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 18px;
}

.FirstSearch {
  background-color: none;
  background: none;
  border: none;
}

.FirstSearchImage {
  display: flex;
  height: 6rem;
  background-color: white;
  padding: 0.5rem;
  border-radius: 18px;
}

.badges {
  margin-top: 4rem;
  margin-left: 3rem;
}

/* Modals */
.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal.modal-show {
  animation: fadeIn 0.1s ease-in-out forwards;
}

.modal.modal-hide {
  animation: fadeOut 0.1s ease-in-out 0.1s forwards;
}

.modal-content {
  position: relative;
  background-color: #fff;
  margin: 6rem;
  padding: 2rem;
  border-radius: 1.25rem;
  width: 50%;
  max-height: 50%;
  overflow: auto;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

.modal.modal-show .modal-content {
  animation: fadeInDown 0.3s ease-in-out forwards;
}

.modal.modal-hide .modal-content {
  animation: fadeOutUp 0.2s ease-in-out forwards;
}

.modal-content h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.modal-content p {
  margin: 1rem 0;
  line-height: 1.5rem;
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.75rem;
  font-weight: bold;
  padding: 0 0.75rem;
  color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  user-select: none;
}

.modal-close:hover,
.modal-close:focus {
  color: rgba(0, 0, 0, 0.5);
}

.modal {
  justify-content: center;
  align-items: center;
}

.modal .modal-content {
  padding: 0;
  height: 50%;
  width: auto;
  max-width: 40%;
  overflow: visible;
  border: 3px solid #fff;
}

/* tsuiraku jk to haijin kyoushi */

.modal .modal-content img {
  width: 50%;
  height: 50%;
}

.modal .modal-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  top: -1rem;
  right: -1rem;
  border-radius: 50%;
  color: #111;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.modal .modal-close:hover,
.modal .modal-close:focus {
  color: #111;
}

.modal.modal-show .modal-content {
  animation: zoomIn 0.3s ease-in-out forwards;
}

.modal.modal-hide .modal-content {
  animation: zoomOut 0.2s ease-in-out forwards;
}

.settings-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 1;
}

/* Animation */
@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsiveness */
@media (max-width: 992px) {
  html {
    font-size: 14px;
  }
  .modal-content {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .modal-content {
    padding: 2rem 1rem 1rem 1rem;
    width: 90%;
  }
  .modal-content h1 {
    margin-bottom: 1.5rem;
  }

  .modal .modal-content {
    max-width: 85%;
  }
}

@media screen and (max-width: 486px) {
  .profile-user-details {
    flex-direction: column;
    gap: 2rem;
  }

  .profile-user-update-button {
    width: 300px;
  }

  .profile-user {
    gap: 4rem;
  }

  .profile-password-change {
    flex-direction: column;
    gap: 2rem;
  }

  .profile-password-change-button {
    width: 300px;
  }
  .profile-password {
    gap: 13rem;
  }

  .profile-membership-details {
    flex-direction: column;
    gap: 2rem;
  }

  .profile-membership {
    gap: 10rem;
  }

  .profile-membership-update-button {
    width: 300px;
  }
}
