.input-accept-or-reject input {
  margin-left: 2rem;
  padding: 2rem;
}

.accept-reject-main {
  position: relative;
  z-index: 1;
}

.accept-reject-card {
  display: flex;
  flex-direction: column;
  width: 55rem;
  height: auto;
  border-radius: 20px;
  background-color: rgb(255 255 255 / 70%);
  padding: 3.5rem 0 2.5rem 0;
}

.submit-button-WOTD {
  border-radius: 8px;
  width: 14rem;
  max-width: 100%;
  height: 3rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: #ffff;
  background: #188fff;
  border: 1px solid #188fff;
  align-self: center;
}

.image {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkbox input {
  height: 1.2rem;
  width: 1.2rem;
}

.radio input {
  height: 1.2rem;
  width: 1.2rem;
}

.radio label {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 480px) {
  .search-card {
    width: 21rem;
    height: 30rem;
  }

  .search-logo {
    height: 9rem;
  }

  .accept-reject-card {
    width: 27rem;
  }
}
