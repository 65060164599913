.song_main {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.song_card {
  width: 800px;
  height: 655px;
  overflow-y: auto;
  /* min-height: 700px; */
  /* min-width: 500px; */
  border-radius: 20px;
  background-color: rgb(255 255 255 / 70%);
  padding: 1.5rem 10px 1.5rem 10px;
  /* margin-top: 2rem; */
  /* margin-bottom: 0rem; */
  position: relative;
}

.song_columns {
  font-size: 15px;
  color: black;
  font-weight: 400;
}

.song_row:hover {
  cursor: pointer;
  background-color: rgb(231, 255, 255);
}

main {
  overflow: visible !important;
  overflow-x: visible !important;
  overflow-y: visible !important;
}
.delete_icon {
  cursor: pointer;
}
.swal-footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.swal-overlay .swal-modal {
  position: relative;
}

.swal-overlay .swal-modal::before {
  /* content: "×"; */
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}
.delete_bar {
  display: inline-block;
}
.delete_bar:hover {
  background-color: rgb(255, 181, 181);
}

#customCloseButton {
  border: none;
  background: none;
  font-size: 27px;
}

@media screen and (max-width: 487px) {
  .song_card {
    min-width: 300px;
    max-width: 350px;
    margin-top: 2rem;
  }
}
