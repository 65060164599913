.typewriter {
    overflow: hidden;
    white-space: pre-wrap;
    animation: blink-caret .5s step-end infinite;
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: inherit; }
  }
  
  .typewriter span {
    display: inline-block;
    overflow: hidden;
    width: 0;
    transition: width 0.05s linear;
  }
  