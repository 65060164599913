.wrwo_main {
  position: relative;
  z-index: 999;
  display: flex;
  /* height: 100%; */
  width: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.wrwo_bg {
  z-index: 990;
  object-fit: cover;
  position: fixed;
  height: 100%;
  width: 100%;
}

.wrwo_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 20px;
}

.wrwo_cover {
  z-index: 999;
  position: relative;
  height: 450px;
  width: 450px;
  border: solid purple 20px;
}

.wrwo_button {
  position: absolute;
  z-index: 999;
  background-color: purple;
  border: none;
  border-radius: 15px;
  color: white;
  font-size: 20px;
  font-weight: 700;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  height: 47px;
  width: auto;
  padding: 10px;
  left: 47%;
  margin-top: 40px;
}
